<template>
  <div class="BJS">
    <Header></Header>
        <!-- 左右两侧 -->
    <div class="Bottom_list">
      <div class="Bottom_list_bx">
        <ReportLeft></ReportLeft>
        <!-- <MainRight></MainRight> -->
          <ReportRight></ReportRight>
      </div>
    </div>
<div class="gotop" v-show="gotop" @click="toTop">回到顶部</div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Axios from "axios";
// import { banner } from "../request/api";
import ReportLeft from "../components/ReportLeft.vue";
import ReportRight from "../components/ReportRight.vue";
import Footer from "../components/Footer.vue";
export default {

  name: "index",
  data() {
    return {
      gotop: false
    };
  },

  components: {
    Header,
    ReportLeft,
    ReportRight,
    Footer
  },
  computed: {},
  methods: {
    // 滚动到顶部
    handleScroll() {
       let scrolltop = document.documentElement.scrollTop || document.body.scrollTop;
      scrolltop > 30 ? (this.gotop = true) : (this.gotop = false);
    },
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果 
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 200;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 30);
    },
  },
  mounted: function () {
    // this.request();
     window.addEventListener("scroll", this.handleScroll, true);
  },
};
</script>


<style scoped>
html,
body {
  background: #f5f5f5;
}
.BJS {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
}
.logo {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
}
.logo img {
  width: 110px;
  height: 30px;
}
.logo button {
  width: 90px;
  height: 30px;
  border-radius: 20px;
  background: #179afe;
  color: white;
  border: none;
  outline: none;
}
.Bottom_list {
  width: 100%;
}
.Bottom_list_bx {
  width: 1200px;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  /* background: red; */
}
.gotop {
  text-align: center;
  position: fixed;
  right: 70px;
  bottom: 70px;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  background: white;
  color: #000000;
}
</style>
