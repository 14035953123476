<template>
  <!-- 品牌 左侧 -->
  <div>
    <!-- <div class="Hot" v-for="(item, index) in List" :key="index">
      <div class="Hot_left">
        <img :src="item.img" v-if="item.img != ''" alt="" />
        <img
          src="https://images.qudonghao.cn//uploads/20210425/b16d16ccf672077c53ef60445a04c4ec.jpg"
          v-if="item.img == ''"
          alt=""
        />
      </div>
      <div class="Hot_right">
        <div class="Hot_right_top">
          {{ item.title }}
        </div>
        <div class="Hot_right_bottom">
          <div class="Hot_right_bottom_left">
            <img
              v-if="item.user_headimgurl != ''"
              :src="item.user_headimgurl"
              alt=""
            />
            <img
              v-if="item.user_headimgurl == ''"
              src="http://www.qudonghao.cn/static/headerimg.png"
              alt=""
            />
            <div class="name">{{ item.user_nickname }}</div>
            <div class="data">{{ item.published }}</div>
          </div>
          <div class="Hot_right_bottom_right">
            <div class="Hot_right_bottom_right_num">
              <img src="../assets/img/dianzan_icon.png" alt="" />
              <span>{{ item.b }}</span>
            </div>
            <div class="Hot_right_bottom_right_num">
              <img src="../assets/img/pinglun_icon.png" alt="" />
              <span>{{ item.comments }}</span>
            </div>
          </div>
        </div>
      </div>
    </div> -->
      <ul class="nav nav-pills">
 <li role="presentation" v-for="(item,index) in guides" :key="index"
  :class="item.id==discovercurrent?'active':''"
 >
  <router-link :to="item.link" exact>{{item.name}}</router-link>
 </li>
 </ul>
      <router-view></router-view>
  </div>
</template>

<script>
import Axios from "axios";
export default {
  data() {
    return {
      List: [],
            discovercurrent:0,
      guides:[
 {
  id:0,
  name:'热门品牌',
  link:'/Report/HotBrands'
 },
 {
  id:1,
  name:'汽车',
  link:'/Report/automobile'
 },
  {
  id:2,
  name:'电商',
  link:'/Report/retailers'
 },
  {
  id:3,
  name:'共享服务',
  link:'/Report/SharedServices'
 },
  {
  id:4,
  name:'旅游出行',
  link:'/Report/Travel'
 },
  {
  id:5,
  name:'视听娱乐',
  link:'/Report/AudioVisual'
 },
  {
  id:6,
  name:'教育培训',
  link:'/Report/EducationTraining'
 },
  {
  id:7,
  name:'消费电子',
  link:'/Report/ConsumerElectronics'
 },
  {
  id:8,
  name:'其他',
  link:'/Report/Other'
 },

 ]
    };
  },
  methods: {
    request() {
      let that = this;
      // var param=new URLSearchParams;
      // param.append("cid","65816");
      Axios({
        method: "post",
        url: "https://weixin.qudong.com/api/index/index",
        params: {
          // category: 824,
        },
      }).then((res) => {
        //console.log(res);
        that.List = res.data.data;
      });
    },
  },
  mounted: function () {
    var that = this;
    // that.request();
  },
};
</script>

<style scoped>
.Hot {
  width: 860px;
  background: #fff;
  padding: 20px;
  border-bottom: 1px solid #ccc;
  display: flex;
}
.Hot_left img {
  width: 215px;
  height: 145px;
  border-radius: 8px;
  object-fit: cover;
}
.Hot_right {
  width: 100%;
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.Hot_right_top {
  margin-top: 10px;
  font-size: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.Hot_right_bottom {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.Hot_right_bottom_left {
  display: flex;
  align-items: center;
}
.Hot_right_bottom_left img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
}
.Hot_right_bottom_left .name {
  font-size: 14px;
  margin-right: 25px;
}
.Hot_right_bottom_left .data {
  font-size: 13px;
  color: #999999;
}
.Hot_right_bottom_right {
  display: flex;
}
.Hot_right_bottom_right_num {
  display: flex;
  align-items: center;
  margin-left: 25px;
}
.Hot_right_bottom_right_num img {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.Hot_right_bottom_right_num span {
  color: #999999;
}

/* 二级导航 */
.nav{
  width:900px;
  height:48px;
  background:#fff;
  display:flex;
  line-height:48px;
font-size: 18px;
justify-content:space-evenly;
}
.nav-pills li{
  margin-right:20px;
}
.router-link-active {
border-bottom:1px solid #1F72D9;
padding-bottom:12px;
color:#1F72D9;
font-size:18px;
font-weight: bold;
}
</style>